import Checkbox from "antd/lib/checkbox";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import axios from "axios";
import cc from "classcat";
import { TrackJS } from "trackjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { TFunction, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as HomeOutlined } from "../../../assets/icons/homeOutlined.svg";
import RetailMainButton from "../../../components/Button/RetailMainButton";
import RetailAuthLayout from "../../../components/Layout/RetailAuthLayout";
import RetailNotification from "../../../components/Notification";
import RetailText from "../../../components/Typography/RetailText";
import { Auth, AuthContext } from "../../../context/AuthProvider";
import cm from "./style.module.scss";

export const addAccount = async (
  access_token: string,
  token: string,
  t: TFunction
) => {
  try {
    await axios.post(
      "/api/account-users/accept-invite",
      {
        access_token: access_token,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    /*     RetailNotification.showNotification(
      "success",
      "",
      t("pages.auth.login.multipleAccountSuccess")
    ); */
  } catch (err) {
    TrackJS.track({
      message: "Add Account Error",
      metadata: {
        endpoint: "/api/account-users/accept-invite",
        responseBody: err?.response?.data,
        statusCode: err?.response?.status,
        errorMessage: err?.message,
        severity: "Medium",
      },
    });
    RetailNotification.showNotification(
      "error",
      "",
      t("pages.auth.login.multipleAccountErr")
    );
  }
};

const LoginPage = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [token, setToken] = useState("");

  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const access_token = localStorage.getItem("access_token");

  const { getUser } = useContext(AuthContext) as Auth;

  const sso = decodeURIComponent(window.location.href).split("token=")[1];

  useEffect(() => {
    if (sso) getUser(sso);
    else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = async (
    email: string,
    password: string,
    recaptcha_response: string
  ) => {
    return axios.post("/auth/login", {
      email,
      password,
      recaptcha_response,
    });
  };

  const handleLoginError = (err: any) => {
    if (err.request.responseText.includes("user is not active"))
      return "notActive";
    if (err.request.responseText.includes("incorrect password"))
      return "incorrectPassword";
    if (err.request.responseText.includes("unsuccessful recaptcha"))
      return "recaptcha";
    return "incorrectPassword";
  };

  const onFinish = async (values: any) => {
    try {
      const res = await login(values.email, values.password, token);
      if (access_token) {
        await addAccount(access_token, res?.data?.token, t);
      }
      await getUser(res?.data?.token);
    } catch (err: any) {
      handleLoginError(err);
      RetailNotification.showNotification(
        "error",
        t(`pages.auth.login.${handleLoginError(err)}`),
        t(`pages.auth.login.${handleLoginError(err)}Text`)
      );
      TrackJS.track({
        message: "Login Error",
        metadata: {
          endpoint: "/auth/login",
          responseBody: err?.response?.data,
          statusCode: err?.response?.status,
          errorMessage: err?.message,
          severity: "High",
        },
      });
    } finally {
      setRefreshReCaptcha(true);
      localStorage.removeItem("access_token");
    }
  };

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  return (
    <RetailAuthLayout>
      <div className={cm.form}>
        <section className={cc(["flex", cm.textContainer])}>
          <div className={cc(["flex", cm.iconContainer])}>
            <HomeOutlined />
          </div>
          <article>
            <h1>{t("pages.auth.login.title")}</h1>
            <p>{t("pages.auth.login.text")}</p>
          </article>
        </section>
        <Form form={form} onFinish={onFinish} autoComplete="off">
          <Form.Item
            label=""
            name="email"
            className={cm.formItem}
            rules={[
              {
                required: true,
                message: t("pages.auth.login.mailErr"),
                type: "email",
              },
            ]}
          >
            <Input placeholder={t("pages.auth.login.mail")} />
          </Form.Item>

          <Form.Item
            label=""
            name="password"
            className={cm.formItem}
            rules={[
              { required: true, message: t("pages.auth.login.passwordErr") },
            ]}
          >
            <Input.Password placeholder={t("pages.auth.login.password")} />
          </Form.Item>

          <div className={cm.subContainer}>
            <Form.Item
              name="remember"
              valuePropName="checked"
              className={cm.remember}
            >
              <Checkbox>{t("pages.auth.login.remember")}</Checkbox>
            </Form.Item>
            <Link to="/forget-password">{t("pages.auth.login.forget")}</Link>
          </div>
          <Form.Item>
            <RetailMainButton
              hasBackground
              htmlType="submit"
              className={cm.btn}
            >
              {t("pages.auth.login.button")}
            </RetailMainButton>
          </Form.Item>
          <div className={cm.signup}>
            <RetailText size="xs" weight="medium" className={cm.text}>
              {t("pages.auth.login.notHaveAnAccount")}
            </RetailText>
            <Link to="/signup">{t("pages.auth.login.signup")}</Link>
          </div>
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        </Form>
      </div>
    </RetailAuthLayout>
  );
};

export default LoginPage;
