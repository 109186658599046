import { Select, Tabs } from "antd";
import useApi from "api";
import { ReactComponent as CoinsOutlined } from "assets/icons/coinsOutlined.svg";
import { ReactComponent as CopyOutlined } from "assets/icons/copyOutlined.svg";
import { ReactComponent as CreditCardOutlined } from "assets/icons/creditCardOutlined.svg";
import { ReactComponent as DownOutlined } from "assets/icons/downOutlined.svg";
import { ReactComponent as MoneyOutlined } from "assets/icons/moneyOutlined.svg";
import { ReactComponent as SwapOutlined } from "assets/icons/swapOutlined.svg";
import { ReactComponent as UpOutlined } from "assets/icons/upOutlined.svg";
import { AxiosResponse } from "axios";
import { TrackJS } from "trackjs";
import cc from "classcat";
import RetailNotification from "components/Notification";
import RetailSelect from "components/Select/RetailSelect";
import RetailInfoTag from "components/Tag/RetailInfoTag";
import RetailPaymentInfoTag from "components/Tag/RetailPaymentInfoTag";
import RetailText from "components/Typography/RetailText";
import RetailTitle from "components/Typography/RetailTitle";
import { Auth, AuthContext } from "context/AuthProvider";
import { useCurrency } from "context/CurrencyProvider";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import useSettings from "hooks/useSettings";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { numFormatter } from "utils/helpers";

import CampaignDetailsModal, {
  CampaignDetailsModalProps,
} from "../../CampaignDetailsModal";
import RetailErrorModal from "../../RetailErrorModal";
import RetailSuccessModal from "../../RetailSuccessModal";
import cm from "../style.module.scss";

export interface BankDetailsProps {
  op: any;
  index: number;
}

const BankDetails = ({ index, op }: BankDetailsProps) => {
  const [active, setActive] = useState(0);

  const copyTextToClipboard = (item: string) =>
    navigator.clipboard.writeText(item);

  const handleClick = () => setActive(index);

  return (
    <div
      className={cc([active === index ? cm.active : "", cm.main])}
      onClick={handleClick}
    >
      <RetailText
        family="poppins"
        weight="medium"
        className={cc(["flex", cm.upperTitle])}
      >
        {active === index ? <UpOutlined /> : <DownOutlined />}
        {op.bank_name}
      </RetailText>
      <div className={cm.body}>
        <RetailText
          family="poppins"
          size="xs"
          weight="medium"
          className={cm.text}
        >
          {op.account_name} ({op.bank_name} - {op.currency} - {op.branch})
        </RetailText>
        <div className="flex">
          <RetailText
            family="poppins"
            size="xs"
            weight="medium"
            className={cm.iban}
          >
            IBAN: <strong>{op.iban}</strong>
          </RetailText>
          <CopyOutlined
            className={cm.copy}
            onClick={() => copyTextToClipboard(op.iban)}
          />
        </div>
        <RetailPaymentInfoTag />
      </div>
    </div>
  );
};

export interface RetailPaymentModalProps extends CampaignDetailsModalProps {
  paymentVisible: boolean;
  onCancel: () => void;
  value: number;
}

const { Option } = Select;

const RetailPaymentModal = ({
  paymentVisible,
  onCancel,
  value,
  ...campaignDetailsModalProps
}: RetailPaymentModalProps) => {
  const { advValue } = useContext(AuthContext) as Auth;

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { api, baseURL, id: advertiser_id, role, isLimitedUser } = useApi();

  const { currency, currencySymbol } = useCurrency();

  const { data } = useSettings("ADVERTISER");

  const { data: banks } = useSettings("PAYMENTS");

  const [active, setActive] = useState("");

  const { options, handleScroll, setSearch } = useInfiniteScroll(
    "products",
    active === "toters_milestone_payment"
  );

  const [mode, setMode] = useState<"success" | "error" | null | boolean>(null);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const [product, setProduct] = useState<any>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const getPaymentProgress = async (id: string) => {
    try {
      searchParams.delete("payment");
      setSearchParams(searchParams);
      setActive("CRAFTGATE");
      await api.get(`/payments/progress/${id}`).then((res) => {
        if (res?.data?.status === "FAILURE") setMode("error");
        else setMode("success");
      });
    } catch (e) {
      setMode("error");
    } finally {
      localStorage.removeItem("transactionId");
    }
  };

  useEffect(() => {
    if (
      window.location.href.includes("payment=craftgate") &&
      baseURL() !== undefined &&
      advertiser_id !== ""
    ) {
      const transactionId = localStorage.getItem("transactionId");
      if (transactionId) getPaymentProgress(transactionId);
      else return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, baseURL, advertiser_id]);

  const taxAmount = value * (data?.data?.tax_rate / 100);

  const amount = value + taxAmount;

  const SuccessModal = () => {
    const type = () => {
      switch (active) {
        case "wire_transfer":
          return "payment";
        case "CRAFTGATE":
          return "craftgate";
        default:
          return "invoice";
      }
    };
    return (
      <RetailSuccessModal
        type={type()}
        visible={mode === "success"}
        setVisible={setMode}
        onClick={close}
      />
    );
  };

  const ErrorModal = () => {
    const type = () => {
      switch (active) {
        case "CRAFTGATE":
          return "craftgate";
        default:
          return "invoice";
      }
    };
    return (
      <RetailErrorModal
        type={type()}
        visible={mode === "error"}
        setVisible={setMode}
        onClick={close}
        selectedPaymentType={active}
        errorMsg={errorMsg}
      />
    );
  };

  const closePayment = () => {
    onCancel();
    setProduct(null);
  };

  const close = () => {
    setMode(null);
    setActive(data?.data?.payment_options[0]);
  };

  const switchConfirmModal = () => {
    switch (mode) {
      case "success":
        return <SuccessModal />;
      case "error":
        return <ErrorModal />;
      default:
        break;
    }
  };

  const creditCardPayment = (res: AxiosResponse) => {
    localStorage.setItem("transactionId", res?.data?.transaction_id);
    window.open(res.data?.page_URL, "_blank");
    closePayment();
  };

  const pay = async () => {
    const callback = `https://${window.location.hostname}/invoices?payment=craftgate`;

    const config = () => {
      const general = {
        amount: value,
        method: active,
        currency,
      };
      switch (active) {
        case "toters_milestone_payment":
          return {
            ...general,
            product_id: product,
          };
        case "CRAFTGATE":
          return {
            ...general,
            callback_url: advValue ? `${callback}&adv=${advValue}` : callback,
          };
        default:
          return general;
      }
    };
    try {
      setLoading(true);
      if (active !== "wire_transfer") {
        await api.post("/payments", config()).then((res) => {
          if (active === "CRAFTGATE") creditCardPayment(res);
          else setMode("success");
        });
      } else {
        setMode("success");
      }
      queryClient.refetchQueries("table");
    } catch (error) {
      const message = error?.response?.data;
      const isString = typeof message === "string";

      if (isString && message.toLowerCase().includes("multiple top-ups")) {
        return RetailNotification.showNotification(
          "error",
          "",
          t("pages.acc.invoice.multipleTopUpsError")
        );
      } else {
        setErrorMsg(JSON.parse(error?.request?.responseText)?.Message);
        setMode("error");
      }
      TrackJS.track({
        message: "Payment Error",
        metadata: {
          endpoint: "payments",
          role,
          isLimitedUser: `Is user limited? ${isLimitedUser}`,
          baseURL: baseURL(),
          responseBody: error?.response?.data,
          statusCode: error?.response?.status,
          errorMessage: error?.message,
          severity: "Medium",
        },
      });
    } finally {
      closePayment();
      setLoading(false);
    }
  };

  const content = () => {
    switch (active) {
      case "wire_transfer":
        return (
          <section>
            <RetailTitle className={cm.paymentTitle} level={5}>
              {t("components.payment.bank")}
            </RetailTitle>

            <div className={cm.wrapper}>
              {banks?.data.map((op: any, index: number) => (
                <BankDetails op={op} index={index} key={index} />
              ))}
            </div>

            <RetailInfoTag type="SETTINGS" closable={false}>
              {t("components.payment.helpUpper")}
              <br />
              {t("components.payment.helpSecondary")}
            </RetailInfoTag>
          </section>
        );
      case "toters_milestone_payment":
        return (
          <RetailSelect
            optionFilterProp="label"
            placeholder={t("components.payment.product_placeholder")}
            showSearch
            value={product}
            onSearch={setSearch}
            onChange={(value) => setProduct(value)}
            onPopupScroll={handleScroll}
            className={cm.paymentProductSelect}
            listHeight={182}
            virtual={false}
          >
            {options?.map((product: any) => (
              <Option key={product.id} value={product.id} label={product.name}>
                <div className="flex">
                  <img
                    src={product.image_url}
                    alt={product.name}
                    className={cm.productImg}
                  />
                  <RetailText
                    family="poppins"
                    size="xs"
                    className={cm.productText}
                  >
                    {product.name}
                  </RetailText>
                </div>
              </Option>
            ))}
          </RetailSelect>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (active === "") setActive(data?.data?.payment_options[0]);
    }, 500);
    return () => clearTimeout(timer);
  }, [data?.data?.payment_options, active]);

  const tabTitle = (op: string) => {
    switch (op) {
      case "wire_transfer":
        return <SwapOutlined />;
      case "CRAFTGATE":
        return <CreditCardOutlined />;
      default:
        return <MoneyOutlined />;
    }
  };

  return (
    <>
      <CampaignDetailsModal
        subtitle={t("components.payment.subtitle")}
        visible={paymentVisible}
        onOk={pay}
        onCancel={closePayment}
        className={active === "toters_milestone_payment" ? cm.paymentModal : ""}
        disabled={
          (active === "toters_milestone_payment" && !product) || loading
        }
        {...campaignDetailsModalProps}
      >
        <section className={cc(["flex", cm.amountBox])}>
          <div className={cc(["flex", cm.singleAmountBox])}>
            <div className={cm.iconBox}>
              <MoneyOutlined />
            </div>
            <article>
              <RetailText weight="medium" size="xxxs" className={cm.amountText}>
                {t("components.payment.amount")}
                <strong>
                  {numFormatter(value)}
                  {currencySymbol}
                </strong>
              </RetailText>
              <RetailText weight="medium" size="xxxs" className={cm.amountText}>
                {t("components.payment.tax")}
                <strong>
                  {numFormatter(taxAmount)}
                  {currencySymbol}
                </strong>
              </RetailText>
            </article>
          </div>

          <div className={cc(["flex", cm.singleAmountBox])}>
            <div className={cm.iconBox}>
              <CoinsOutlined />
            </div>
            <article>
              <RetailText weight="medium" size="xxxs" className={cm.verifyText}>
                {t("components.payment.verifyAmount")}
              </RetailText>
              <RetailText weight="medium" className={cm.verifyText}>
                <strong className={cm.singleStrong}>
                  {numFormatter(amount)}
                  {currencySymbol}
                </strong>
              </RetailText>
            </article>
          </div>
        </section>
        <section>
          <RetailTitle className={cm.paymentTitle} level={5}>
            {t("components.payment.title")}
          </RetailTitle>
          <Tabs
            onChange={(activeKey) => setActive(activeKey)}
            className={cm.selectBox}
            activeKey={active}
          >
            {data?.data?.payment_options?.map((op: string) => (
              <Tabs.TabPane
                tab={
                  <>
                    {tabTitle(op)}
                    {t(`components.payment.${op?.toLowerCase()}`)}
                  </>
                }
                key={op}
              >
                {content()}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </section>
      </CampaignDetailsModal>
      {switchConfirmModal()}
    </>
  );
};

export default RetailPaymentModal;
